<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="400" min-height="300px">
      <v-card class="dialog-bg" >
        <v-card-title class="headline grey lighten-2"> </v-card-title>
        <v-card-text class="sent mt-6 mb-6">
          <img src="media/svg/misc/sent.svg"  v-if="buttonName != 'Close'"/>
          <img src="media/svg/misc/cancel.svg"  width="98" height="99"  v-else/>
          
          <h1 class="sent-title mt-6 mb-6">
            {{message}}
          </h1>
          <div class="back-to-page">
            <b-button class="back-to-page-button" @click="closeClicked"
              >{{buttonName}}</b-button
            >
          </div>
        </v-card-text>
        <v-card-actions />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Image from "../../view/pages/vue-bootstrap/Image.vue";
export default {
  components: { Image },
  props: ["buttonName","type","message"],
  data() {
    return {
      dialog: false,
    };
  },
  methods:{
    closeClicked(){
      this.$emit("alertClose",'');
      this.dialog = false;
    }
  }
};
</script>
<style scoped>
.v-card__title {
  margin-bottom: 0px !important;
  padding: none !important;
}
.dialog-bg {
  text-align: center !important;
  background-color: #f3fcff !important;
  border: 1px solid #707070 !important;
  border-radius: 20px !important;
}
.sent[data-v-18f47a1a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex-direction: column;
}
.sent-title {
  font-weight: bold;
  color: black;
}
.back-to-page {
  border-radius: 15px;
}
.v-application .grey.lighten-2 {
    background-color: #f3fcff !important;
    /* border-color: #e0e0e0 !important; */
}

</style>

<template>
  <div class="create-payout-step1">
    <form ref="form" class="form-data">
      <!-- ADD PAYOUT NAME -->
      <b-form-group>
        <b-row class="border-bottom">
          <b-col class="col-3">
            <b-form-group
              id="name-fieldset"
              class="input-label"
              label="Name of the Payout"
              label-for="name"
            >
              <b-input
                id="name"
                class="input-field custom-input"
                :class="name && !nameValidate ? 'danger' : ''"
                col="3"
                v-model="name"
                type="text"
                required
                placeholder="Payout name"
                @keyup="nameValidation"
              >
              </b-input>
            </b-form-group>
          </b-col>

          <!-- DEFAULT CURRENCY -->
          <b-col class="col-3">
            <b-form-group
              id="name-fieldset"
              class="input-label"
              label="Default Currency"
              label-for="defaultCurrency"
            >
              <b-dropdown
                id="defaultCurrency"
                :text="defaultCurrency"
                class="currency-dropdown"
              >
                <b-dropdown-item @click="defaultCurrency = 'AED'"
                  >AED</b-dropdown-item
                >
                <b-dropdown-item @click="defaultCurrency = 'SAR'"
                  >SAR</b-dropdown-item
                >
                <b-dropdown-item @click="defaultCurrency = 'USD'"
                  >USD</b-dropdown-item
                >
              </b-dropdown>
            </b-form-group>
          </b-col>

          <!-- DEFAULT PAYOUT TYPE -->
          <b-col class="col-3">
            <b-form-group
              id="name-fieldset"
              class="input-label"
              label="Default Payout"
              label-for="payoutType"
            >
              <b-dropdown
                id="payoutType"
                :text="payoutType"
                class="currency-dropdown"
              >
                <b-dropdown-item @click="payoutType = 'CPO'"
                  >CPO</b-dropdown-item
                >
                <b-dropdown-item @click="payoutType = 'CPS'"
                  >CPS</b-dropdown-item
                >
              </b-dropdown>
            </b-form-group>
          </b-col>

          <!-- DEFAULT PAYOUT -->
        </b-row>
      </b-form-group>

      <!-- ADD DATE -->
      <b-form-group
        id="fieldset"
        label="Add Date"
        style="display: flex; margin-bottom: 0px"
        label-for="add-date"
      >
        <div
          class="switch switch-sm switch-primary p-3"
          style="
            align-items: baseline;
            display: inline-block;
            margin-left: 280px;
          "
        >
          <label style="line-height: 26px; color: grey !important">
            Switch to {{ is_end_date ? "open" : "close" }} date

            <input
              type="checkbox"
              name=""
              v-model="is_end_date"
              @change="changeEndDate"
            />

            <span style="float: right; margin-left: 8px"></span>
          </label>
        </div>
      </b-form-group>

      <b-row id="add-date" class="border-bottom" style="align-items: baseline">
        <b-col class="pb-0 pt-0 col-3">
          <b-form-group
            id="fieldset-start-date"
            label-cols-sm="4"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="10"
            label="from"
            label-for="start-date"
          >
            <b-form-datepicker
              id="start-date"
              no-flip
              v-model="startDate"
              @context="onSelectStartDate"
              placeholder="1/10/2020"
              locale="en"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            ></b-form-datepicker>
          </b-form-group>
        </b-col>

        <b-col class="pb-0 pt-0 col-3">
          <b-form-group
            id="fieldset-end-date"
            label-cols-sm="4"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="10"
            label="to"
            label-for="end-date"
            v-if="is_end_date"
          >
            <b-form-datepicker
              id="end-date"
              no-flip
              v-model="endDate"
              @context="onSelectEndDate"
              placeholder="31/10/2020"
              locale="en"
              :min="startDate"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            ></b-form-datepicker>
          </b-form-group>
          <label style="padding-top: 20px" v-else>and onwards</label>
        </b-col>

        <!-- <b-col class="pb-0 pt-0 col-3">
          
        </b-col> -->
      </b-row>

      <!-- ADD SPECIFIC REGION -->
      <b-form-group>
        <b-row class="border-bottom">
          <b-col class="col-6">
            <b-form-group class="input-label col-12 pl-0 mt-5">
              <label
                ><b-form-checkbox
                  class="custom-control custom-checkbox checkbox-xl"
                  v-model="is_region"
                >
                  ADD SPECIFIC REGIONS
                </b-form-checkbox>
              </label>
              <v-autocomplete
                attach="attach"
                class="mt-3 dashboard-autocomplete"
                v-if="is_region"
                v-model="selectedRegions"
                style="width: 100%"
                :items="regions"
                chips
                outlined
                multiple
                placeholder="Search regions.."
                item-text="name"
                item-value="name"
                no-data-text="No regions available."
                height="40"
              >
              </v-autocomplete>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>
      <!-- ADD SPECIFIC CHANNELS -->
      <b-form-group>
        <b-row class="border-bottom">
          <b-col class="col-6">
            <b-form-group class="input-label col-12 pl-0">
              <label
                ><b-form-checkbox
                  class="custom-control custom-checkbox checkbox-xl"
                  v-model="is_channel"
                >
                  ADD SPECIFIC CHANNELS
                </b-form-checkbox>
              </label>
              <v-autocomplete
                class="mt-3"
                v-if="is_channel"
                v-model="selectedChannels"
                attach="attach"
                :items="channels"
                outlined
                chips
                multiple
                placeholder="Search channels"
                no-data-text="No channels available."
                height="40"
              >
              </v-autocomplete>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <!-- ADD REVENUE SLAB -->
      <b-form-group id="fieldset" label="Add Rule" label-for="add-rule">
        <b-row id="add-rule" class="border-bottom">
          <b-col class="pb-0 pt-4 col-6">
            <b-form-group class="input-label">
              <label
                ><b-form-checkbox
                  class="custom-control custom-checkbox checkbox-xl"
                  v-model="is_revenue_slab"
                >
                  ADD {{ is_order ? "ORDERS" : "REVENUE" }} SLABS
                </b-form-checkbox>
              </label>

              <!-- here component will come -->
              <div v-if="is_revenue_slab && payout_rules.length">
                <b-row
                  v-for="(slab, index) in payout_rules"
                  :key="index"
                  class="slab-item-box"
                >
                  <b-col class="pb-0 pt-0 col-5 h-100">
                    <b-form-group
                      id="fieldset-start-date"
                      label-cols-sm="4"
                      label-cols-lg="2"
                      content-cols-sm
                      content-cols-lg="10"
                      label="from"
                      label-for="start-date"
                    >
                      <b-input-group style="width: 100%">
                        <template #prepend v-if="!is_order">
                          <b-dropdown disabled :text="slab.payout_currency">
                          </b-dropdown>
                        </template>
                        <!-- from order -->
                        <b-form-input
                          disabled
                          v-if="is_order"
                          v-model="slab.from_order"
                          type="number"
                          min="0"
                          required
                        ></b-form-input>
                        <!-- from revenue -->
                        <b-form-input
                          disabled
                          v-if="!is_order"
                          v-model="slab.from_revenue"
                          type="number"
                          min="0"
                          required
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col class="pb-0 pt-0 col-5 h-100">
                    <b-form-group
                      id="fieldset-start-date"
                      label-cols-sm="4"
                      label-cols-lg="2"
                      content-cols-sm
                      content-cols-lg="10"
                      label="to"
                      label-for="start-date"
                    >
                      <b-input-group
                        style="width: 100%"
                        v-if="!openEnded || index !== payout_rules.length - 1"
                      >
                        <template #prepend v-if="!is_order">
                          <b-dropdown disabled :text="slab.payout_currency">
                          </b-dropdown>
                        </template>
                        <!-- to order -->
                        <b-form-input
                          v-if="is_order"
                          v-model="slab.to_order"
                          type="number"
                          :min="Number(slab.from_order)"
                          required
                        ></b-form-input>
                        <!-- to revenue -->
                        <b-form-input
                          v-if="!is_order"
                          v-model="slab.to_revenue"
                          type="number"
                          required
                        ></b-form-input>
                      </b-input-group>
                      <b-input
                        v-if="openEnded && index == payout_rules.length - 1"
                        value="ABOVE"
                        readonly
                        >ABOVE</b-input
                      >
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    class="h-100"
                    v-if="index == payout_rules.length - 1"
                  >
                    <div style="float: right">
                      <span class="switch switch-sm switch-primary p-3">
                        <label>
                          <span style="font-size: 13px; line-height: 26px">{{
                            openEnded
                              ? "TURN OFF INFINITE RANGE"
                              : "TURN ON INFINITE RANGE"
                          }}</span>
                          <input
                            type="checkbox"
                            name=""
                            v-model="openEnded"
                            @click="
                              !openEnded
                                ? is_order
                                  ? (slab.to_order = 'ABOVE')
                                  : (slab.to_revenue = 'ABOVE')
                                : is_order
                                ? (slab.to_order = null)
                                : (slab.to_revenue = null)
                            "
                          />
                          <span></span>
                        </label>
                      </span>
                    </div>
                  </b-col>
                </b-row>
                <p v-if="showGreaterError" style="padding-top: 5px; color: red">
                  To slab must be greater then From Slab.
                </p>
              </div>
              <!-- add new row  -->
              <b-row
                v-if="is_revenue_slab"
                class="mt-10"
                style="align-items: center"
              >
                <!-- MONTHLY DURATION -->

                <!-- DEFAULT CURRENCY -->
                <b-col class="col-6">
                  <b-form-group
                    id="name-fieldset"
                    class="input-label"
                    label="Duration"
                    label-for="defaultMonth"
                  >
                    <b-dropdown
                      id="defaultMonth"
                      :text="defaultMonth"
                      class="currency-dropdown"
                    >
                      <b-dropdown-item @click="defaultMonth = 'Weekly'"
                        >Weekly</b-dropdown-item
                      >
                      <b-dropdown-item @click="defaultMonth = 'Monthly'"
                        >Monthly</b-dropdown-item
                      >
                      <b-dropdown-item @click="defaultMonth = 'Quarterly'"
                        >Quarterly</b-dropdown-item
                      ><b-dropdown-item @click="defaultMonth = 'Yearly'"
                        >Yearly</b-dropdown-item
                      >
                    </b-dropdown>
                  </b-form-group>
                </b-col>

                <b-col class="col-3 text-right">
                  <b>REMOVE</b>
                  <v-icon
                    color="red"
                    @click="removeRuleslab()"
                    :disabled="!payout_rules.length"
                    >mdi-delete</v-icon
                  >
                </b-col>

                <b-col class="col-3 text-right">
                  <b>ADD NEW</b>
                  <v-icon
                    class="mdi-plus-content2 plus-icon"
                    @click="addRuleSlab"
                    :disabled="!is_payout_rule_valid || openEnded"
                    >mdi-plus</v-icon
                  >
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>

          <b-col class="pb-0 pt-0 col-3">
            <span class="switch switch-sm switch-primary p-3">
              <label style="line-height: 26px">
                Switch to {{ is_order ? "revenue" : "orders" }}
                <input
                  type="checkbox"
                  name=""
                  v-model="is_order"
                  @change="changeOrder"
                />
                <span></span>
              </label>
            </span>
          </b-col>
        </b-row>
      </b-form-group>

      <!-- ADD CUSTOMER TYPE -->
      <b-form-group>
        <b-row class="border-bottom">
          <b-col class="col-6">
            <b-form-group class="input-label col-12 pl-0">
              <label
                ><b-form-checkbox
                  class="custom-control custom-checkbox checkbox-xl"
                  v-model="is_customer_type"
                >
                  ADD CUSTOMER TYPE
                </b-form-checkbox>
              </label>
              <v-autocomplete
                class="mt-3"
                v-if="is_customer_type"
                v-model="selectedCustomerTypes"
                attach="attach"
                :items="customers"
                outlined
                chips
                multiple
                placeholder="Search customer type.."
                no-data-text="No customer type available."
                height="40"
              >
              </v-autocomplete>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <!-- ADD COUPON SERIES -->
      <b-form-group>
        <b-row class="border-bottom">
          <b-col class="col-6">
            <b-form-group class="input-label col-12 pl-0">
              <label
                ><b-form-checkbox
                  class="custom-control custom-checkbox checkbox-xl"
                  v-model="is_coupon_series"
                >
                  ADD COUPON SERIES
                </b-form-checkbox>
              </label>
              <v-autocomplete
                class="mt-3"
                v-if="is_coupon_series"
                v-model="selectedCouponSeries"
                attach="attach"
                :items="couponSeries"
                outlined
                chips
                multiple
                placeholder="Search coupons.."
                item-text="series_name"
                return-object
                no-data-text="No coupons available."
                height="40"
              >
              </v-autocomplete>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <!-- ADD BUTTON -->
      <b-form-group class="mt-10">
        <b-button
          :disabled="!isFormValidated || isLoading"
          class="add-btn"
          @click="handleSubmit"
          >Add <b-spinner v-if="isLoading" small type="grow"></b-spinner>
        </b-button>
      </b-form-group>
    </form>
  </div>
</template>
<style lang="scss">
.create-payout-step1 {
  .form-data {
    margin-top: 30px;
    .b-form-datepicker {
      background: #f3fcff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      height: 50px;
      font-size: 18px;
    }
    .v-autocomplete {
      .v-input__slot {
        background: rgb(243 252 255);
        border: none;
        border-radius: 5px;
      }
    }
    .v-autocomplete:not(.v-input--is-focused).v-select--chips input {
      max-height: 25px !important;
    }

    input {
      height: 50px;
      background: #f3fcff 0% 0% no-repeat padding-box;
      font-size: 18px;
      color: #000000;
      border-radius: 5px;
      border: none;
    }

    .danger {
      border-color: red;
    }
    label {
      align-self: center;
      font-size: 15px !important;
      color: #000000;
    }
    .sub-label {
      font-size: 14px !important;
      font-weight: bold !important;
    }
    #checkbox-1::after {
      width: 35px;
      height: 32px;
      background: #00a4f3 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
    }

    .checkbox-xl .custom-control-label::before,
    .checkbox-xl .custom-control-label::after {
      width: 35px;
      height: 32px;
      border-radius: 8px;
      opacity: 1;
    }

    .checkbox-xl .custom-control-label {
      padding-left: 30px;
      padding-top: 8px;
    }
    .campaign-label {
      text-align: left;
      font: normal normal bold 18px/27px Poppins;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
    .add-btn {
      float: right;
      width: 229px;
      height: 53px;
      background: #1374d6 0% 0% no-repeat padding-box;
      border-radius: 10px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 1.6px;
      color: #ffffff;
    }
    .currency-dropdown {
      width: 100%;
      height: 50px;
      button,
      button:focus {
        text-align: left;
        background: #f3fcff;
        color: #000000;
        font-size: 18px;
        font-weight: 500;
      }
      button::after {
        font-size: 14px;
        color: #000000;
        line-height: 30px;
        float: right;
      }
      ul {
        width: 100%;
      }
    }
    .v-chip--select {
      background: #e8f1fb !important;
      color: #000 !important;
      font-weight: bold !important;
    }
    .v-input__control {
      fieldset {
        border: 1px solid #d4d4d4;
        border-radius: 9px;
      }
    }
    .slab-item-box {
      // margin: 20px 0 0 0;
      // padding: 22px 0 0px 0px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #d4d4d4;
      border-radius: 9px;
      margin: 10px 0 10px 0px;
      padding: 0px 0px 0px 5px;
      height: 69px;
      .form-group {
        margin-bottom: 10px 0px 10px 0px;
        height: 100%;
        align-items: center;
      }
      input {
        height: 45px;
      }
    }
    .plus-icon {
      background: #1374d6 0% 0% no-repeat padding-box;
      border-radius: 137px;
      margin: 0px 0px 2px 12px;
      cursor: pointer;
    }
    .border-bottom {
      border-bottom: 1px solid #707070;
    }
  }
}
.rdbtn {
  margin-left: 120px !important;
}
</style>

<script>
import ApiService from "../../../core/services/api.service";
import { bus } from "../../../main";

export default {
  name: "create-payout-step1",
  props: ["type", "changedStep"],
  components: {},
  data: () => ({
    showGreaterError: false,
    client_id: null,
    startDate: "",
    startCompleteDate: null,
    endCompleteDate: null,
    endDate: "",
    name: "",
    payoutType: "CPO",
    defaultCurrency: "AED",

    is_region: true,
    is_channel: false,
    is_revenue: false,
    is_customer_type: false,
    is_coupon_series: false,
    isLoading: false,
    nameValidate: false,
    is_end_date: false,
    is_order: false,
    selectedRegions: ["UAE", "SAU", "BHR", "KWT"],
    selectedCustomerTypes: [],
    selectedCouponSeries: [],
    regions: [
      { name: "UAE" },
      { name: "SAU" },
      { name: "BHR" },
      { name: "KWT" },
    ],
    selectedChannels: [],
    channels: ["AFFILIATES", "INFLUENCERS"],
    currencies: [
      {
        label: "SAR",
        value: "SAR",
      },
      {
        label: "AED",
        value: "AED",
      },
      {
        label: "USD",
        value: "USD",
      },
      {
        label: "KUW",
        value: "KUW",
      },
      {
        label: "QAR",
        value: "QAR",
      },
    ],
    customers: ["RETURNING", "NEW"],
    couponSeries: [],
    is_revenue_slab: false,

    revenue_slab_currency: "AED",
    revenue_slab_budget: null,
    defaultMonth: "Monthly",
    payout_rules: [],
    payoutDetail: null,
    openEnded: false,
    invalid_to_slab: false,
    addPayout: "",
  }),
  async mounted() {
    // this.selectedCustomerTypes = this.customers;
    this.addPayout = localStorage.getItem("copyPayout");
    // edited flow
    if (this.type == "edit") {
      await this.getPayoutDetails(this.$route.params.id);
    }
    this.client_id =
      this.type == "edit" ? this.payoutDetail.client_id : this.$route.params.id;
    await this.getCouponSeries();

    if (this.addPayout) {
      this.getPayoutDetails(this.addPayout);
      // this.copyItem(addPayout)
    }
  },

  created() {
    bus.$on("changeSteps", (data) => {
      console.log(data);
      this.handleSubmit();
    });
  },
  methods: {
    /* event triggered on selecting start date */
    onSelectStartDate(ctx) {
      this.startCompleteDate = ctx.activeDate;
      this.startDate = ctx.activeYMD;
    },

    /* event triggered on selecting end date */
    onSelectEndDate(ctx) {
      this.endCompleteDate = ctx.activeDate;
      this.endDate = ctx.activeYMD;
    },

    changeOrder(e) {
      this.showGreaterError = false;
      this.payout_rules = [];
      this.payout_rules.push({
        from_revenue:
          this.payout_rules.length && this.payout_rules[0].to_revenue
            ? Number(this.payout_rules[0].to_revenue) + 1
            : 0,
        to_revenue: null,
        from_order:
          this.payout_rules.length && this.payout_rules[0].to_order
            ? Number(this.payout_rules[0].to_order) + 1
            : 0,
        to_order: null,
        customer_type: "",
        payout_type: "",
        payout: null,
        payout_currency: this.defaultCurrency,
      });

      if (this.is_order) {
        this.is_revenue = false;
      }
      if (!this.is_order) {
        this.is_revenue = true;
      }
    },

    changeEndDate() {
      if (this.is_end_date) {
        if (this.startDate > this.endDate) {
          this.endDate = this.startDate;
        }
      }
    },

    // onChangeInfiniteRange(index){
    //   debugger;
    //   this.payout_rules[index].to_revenue = this.openEnded == false ? "above" :
    //   this.payout_rules[index].to_revenue
    // },

    async getCouponSeries() {
      let res = await ApiService.post(
        "/coupons/coupon-series/search-coupon-series",
        {
          client_id:
            this.type == "edit"
              ? this.payoutDetail.client_id
              : this.$route.params.id,
        }
      );
      console.log("c series", res.data.data);
      this.couponSeries = res.data.data;
    },

    handleSubmit() {
      let last_index = this.payout_rules.length - 1;
      if (
        last_index > -1 &&
        Number(this.payout_rules[last_index].from_order) >
          Number(this.payout_rules[last_index].to_order)
      ) {
        this.showGreaterError = true;
        window.scrollTo(0, screen.height / 1);
        return;
        // this.rules[last_index].to_revenue_slab_cost =
        //   Number(this.rules[last_index].from_revenue_slab_cost) + 1;
      }
      if (
        last_index > -1 &&
        Number(this.payout_rules[last_index].from_revenue) >
          Number(this.payout_rules[last_index].to_revenue)
      ) {
        this.showGreaterError = true;
        window.scrollTo(0, screen.height / 1);
        return;
        // this.rules[last_index].to_revenue_slab_cost =
        //   Number(this.rules[last_index].from_revenue_slab_cost) + 1;
      }
      const payload = {
        client_id: this.client_id,
        influencer_id: "",
        name: this.name,
        default_payout_type: this.payoutType,
        channels: this.selectedChannels,
        regions: this.selectedRegions,
        from_date: this.startDate,
        to_date: this.endDate,
        default_currency: this.defaultCurrency,
        coupon_series: this.selectedCouponSeries,
        customer_type: this.selectedCustomerTypes,
        payout_rules: this.payout_rules,
        is_order: this.is_order,
        is_customer_type: this.is_customer_type,
        is_revenue_slab: this.is_revenue_slab,
        is_region: this.is_region,
        is_channel: this.is_channel,
        is_revenue: this.is_revenue,
        is_coupon_series: this.is_coupon_series,
        is_end_date: this.is_end_date,
        payout_rule_type: this.is_revenue_slab
          ? this.is_order
            ? "order"
            : "revenue"
          : "",
      };
      this.$emit("payout-payload", payload);
    },
    nameValidation(e) {
      let pattern = /[^a-zA-Z0-9 \\,\\.\\;\\-]/g;
      let value = e.target ? e.target.value : e;
      if (value !== "" && pattern.test(value)) {
        this.nameValidate = false;
        return;
      }
      this.nameValidate = true;
    },
    addRuleSlab() {
      this.showGreaterError = false;
      for (let i = 0; i < this.payout_rules.length; i++) {
        if (
          Number(this.payout_rules[i].from_revenue) >
          Number(this.payout_rules[i].to_revenue)
        ) {
          this.showGreaterError = true;
          return;
          // this.payout_rules[last_index].to_revenue =
          //   Number(this.payout_rules[last_index].from_revenue) + 1;
        }
        if (
          Number(this.payout_rules[i].from_order) >
          Number(this.payout_rules[i].to_order)
        ) {
          this.showGreaterError = true;
          return;
          // this.payout_rules[last_index].to_revenue =
          //   Number(this.payout_rules[last_index].from_revenue) + 1;
        }
      }
      let last_index = this.payout_rules.length - 1;
      // extra validation to check if some set to revenue less than from revenue and trying to create new rule
      if (
        last_index > -1 &&
        Number(this.payout_rules[last_index].from_revenue) >
          Number(this.payout_rules[last_index].to_revenue)
      ) {
        this.showGreaterError = true;
        return;
        // this.payout_rules[last_index].to_revenue =
        //   Number(this.payout_rules[last_index].from_revenue) + 1;
      }

      if (
        last_index > -1 &&
        Number(this.payout_rules[last_index].from_order) >
          Number(this.payout_rules[last_index].to_order)
      ) {
        this.payout_rules[last_index].to_order =
          Number(this.payout_rules[last_index].from_order) + 1;
      }

      this.payout_rules.push({
        from_revenue:
          this.payout_rules.length && this.payout_rules[last_index].to_revenue
            ? Number(this.payout_rules[last_index].to_revenue) + 1
            : 0,
        to_revenue: null,
        from_order:
          this.payout_rules.length && this.payout_rules[last_index].to_order
            ? Number(this.payout_rules[last_index].to_order) + 1
            : 0,
        to_order: null,
        customer_type: "",
        payout_type: "",
        payout: null,
        payout_currency: this.defaultCurrency,
      });
    },
    removeRuleslab() {
      // remove last index
      this.payout_rules.splice(-1);
    },

    // get payout details and map data to input fields
    async getPayoutDetails(id) {
      let response = await ApiService.get(`/payout/${id}`);
      this.payoutDetail = response.data.data;
      this.$emit("client_name", this.payoutDetail.client_name);

      if (this.addPayout) {
        delete this.payoutDetail._id;
      }

      this.is_channel = this.payoutDetail.is_channel;
      // this.payoutDetail.channels.length > 0 ? true : false;
      this.selectedChannels = this.payoutDetail.channels;
      this.is_region = true;
      // this.payoutDetail.regions.length > 0 ? true : false;
      this.selectedRegions = this.payoutDetail.regions;
      this.is_revenue = this.payoutDetail.is_revenue;
      this.is_revenue_slab = this.payoutDetail.is_revenue_slab;
      this.is_coupon_series = this.payoutDetail.is_coupon_series;

      this.client_id = this.payoutDetail.client_id;
      this.influencer_id = this.payoutDetail.influencer_id;
      this.name = this.payoutDetail.name;
      this.nameValidation(this.name);
      this.payoutType = this.payoutDetail.default_payout_type;

      this.is_end_date = this.payoutDetail.is_end_date;
      this.is_order = this.payoutDetail.is_order;
      this.is_customer_type = this.payoutDetail.is_customer_type;

      this.startDate = this.payoutDetail.from_date;
      this.endDate = this.payoutDetail.to_date;
      this.defaultCurrency = this.payoutDetail.default_currency;

      // filter selected coupons from coupun series
      // this.selectedCouponSeries = this.couponSeries.filter((x) => {
      //   let found = this.payoutDetail.coupon_series.find(
      //     (c) => c.coupon_series_id == x._id
      //   );
      //   if (found) {
      //     return x;
      //   }
      // });
      this.payoutDetail.payout_rules[0].from_revenue = 0;
      this.selectedCouponSeries = this.payoutDetail.coupon_series.map((x) => {
        return {
          series_name: x.coupon_series_name,
          _id: x.coupon_series_id,
        };
      });
      // setting coupon series check if there's any selected coupons already
      // this.is_coupon_series = this.payoutDetail.is_coupon_series
      // this.payoutDetail.coupons.length > 0 ? true : false;
      this.selectedCustomerTypes = this.payoutDetail.customer_type
        ? this.payoutDetail.customer_type
        : [];
      // this.is_customer_type = this.payoutDetail.is_customer_type
      // this.payoutDetail.customer_type &&
      // this.payoutDetail.customer_type.length > 0
      //   ? true
      //
      console.log("+++++_____++++", this.payoutDetail.payout_rules);
      (this.payout_rules = this.payoutDetail.payout_rules.length
        ? this.payoutDetail.payout_rules
        : []),
        "from_revenue";

      // infinite range toggle 'on' when to_revenue is above
      this.openEnded =
        this.payout_rules[this.payout_rules.length - 1].to_revenue == "ABOVE" ||
        this.payout_rules[this.payout_rules.length - 1].to_order == "ABOVE"
          ? true
          : false;
      // this.is_revenue = this.payoutDetail.is_revenue_slab;
      // if (this.payoutDetail.payout_rules.length > 0) {
      // this.is_order = this.payoutDetail.is_order
      //this.payoutDetail.payout_rules[0].from_order == null ? false : true;
      //  }
    },
    removeDuplicates(myArr, prop) {
      console.log("myArr", myArr);
      console.log("prop", prop);
      return myArr.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
      });
      return myArr;
    },
    async onChange(event, indexValue, slab) {
      console.log(event, indexValue, slab);
      if (slab == "order") {
        this.payout_rules[indexValue].to_order =
          Number(event) < this.payout_rules[indexValue].from_order
            ? null
            : Number(event);
        this.invalid_to_slab =
          Number(event) < this.payout_rules[indexValue].from_order
            ? true
            : false;
      } else {
        console.log("from", "target", "to");
        console.log(
          this.payout_rules[indexValue].from_revenue,
          event,
          this.payout_rules[indexValue].to_revenue
        );
        this.payout_rules[indexValue].to_revenue =
          Number(event) < this.payout_rules[indexValue].from_revenue
            ? null
            : Number(event);
        this.invalid_to_slab =
          Number(event) < this.payout_rules[indexValue].from_revenue
            ? true
            : false;
      }
      this.$nextTick();
    },
  },
  computed: {
    isFormValidated() {
      if (this.name && this.nameValidate) {
        return true;
      }
      return false;
    },
    is_payout_rule_valid() {
      if (!this.payout_rules.length) return true;
      let last_index = this.payout_rules.length - 1;
      if (
        this.payout_rules[last_index].to_revenue ||
        this.payout_rules[last_index].to_order
      )
        return true;

      return false;
    },
  },

  watch: {
    is_revenue_slab(value) {
      if (value === true && this.payout_rules.length === 0) {
        this.addRuleSlab();
      }
    },
    is_end_date() {
      if (!this.is_end_date) {
        this.endDate = "";
      }
    },
    changedStep(ui) {},
    defaultCurrency(value) {
      if (value && this.payout_rules.length) {
        this.payout_rules = this.payout_rules.map((x) => {
          return {
            ...x,
            payout_currency: value,
          };
        });
      }
    },
    isFormValidated() {
      this.$emit("is-validate", this.isFormValidated);
    },
  },
};
</script>

<template>
  <div class="create-payout-step2">
    <AlertDialog
      :buttonName="'Close'"
      ref="alertDialog"
      :message="errors ? errors : ''"
    />
    <b-row class="mb-15">
      <b-col>
        <strong>DATE</strong>
        <br />
        {{ formatDate(payload.from_date) }} to {{ formatDate(payload.to_date) }}
      </b-col>
      <b-col>
        <strong>PAYOUT NAME</strong>
        <br />
        {{ payload.payout_name ? payload.payout_name : payload.name }}
      </b-col>
      <b-col>
        <strong>CHANNEL(s)</strong>
        <br />
        {{ payload.channels.join() }}
      </b-col>
      <b-col>
        <strong>DEFAULT PAYOUT TYPE</strong>
        <br />
        {{ payload.default_payout_type }}
      </b-col>
    </b-row>

    <b-row class="mb-15">
      <b-col>
        <strong>COUPON SERIES</strong>
        <br />

        {{ payload.coupon_series.map((x) => x.series_name).join() }}
      </b-col>
      <b-col>
        <strong>DEFAULT CURRENCY</strong>
        <br />
        {{ payload.default_currency }}
      </b-col>
      <b-col>
        <strong>Region(s)</strong>
        <br />
        {{ payload.regions.join() }}
      </b-col>
      <b-col> </b-col>
    </b-row>

    <b-row class="15">
      <b-col>
        <h4><strong>Payout Models</strong></h4>
      </b-col>
    </b-row>

    <!-- if user not select revenue_slab & customer type both -->
    <div
      v-if="
        !payload.is_revenue_slab && !payload.is_customer_type && rules.length
      "
    >
      <b-row>
        <b-col class="payout-slab col-6">
          <strong>PAYOUT</strong>
          <br />
          <b-form>
            <b-input-group>
              <template #prepend>
                <b-dropdown
                  disabled
                  :text="
                    rules[0].payout_type === 'CPS'
                      ? rules[0].payout_percent
                      : rules[0].payout_currency
                  "
                  class="currency-dropdown"
                >
                  <b-dropdown-item
                    v-if="rules[0].payout_type == 'CPO'"
                    @click="rules[0].payout_currency = 'AED'"
                    >AED</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="rules[0].payout_type == 'CPO'"
                    @click="rules[0].payout_currency = 'USD'"
                    >USD</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="rules[0].payout_type == 'CPS'"
                    @click="rules[0].payout_percent = '%'"
                    >%</b-dropdown-item
                  >
                </b-dropdown>
              </template>

              <b-form-input
                type="number"
                v-model="rules[0].payout"
              ></b-form-input>

              <template #append>
                <b-dropdown
                  :text="rules[0].payout_type"
                  class="payout-dropdown"
                >
                  <b-dropdown-item
                    @click="
                      rules[0].payout_type = 'CPO';
                      changeInPayoutType(rules[0]);
                    "
                    >CPO</b-dropdown-item
                  >
                  <b-dropdown-item
                    @click="
                      rules[0].payout_type = 'CPS';
                      changeInPayoutType(rules[0]);
                    "
                    >CPS</b-dropdown-item
                  >
                  <!-- <b-dropdown-item @click="rules[0].payout_type = 'CPC';changeInPayoutType(rules[0])"
                    >CPC</b-dropdown-item
                  > -->
                </b-dropdown>
              </template>
            </b-input-group>
          </b-form>
        </b-col>
      </b-row>
    </div>

    <!-- If the user adds only customer type and not revenue slabs-->
    <div v-if="payload.is_customer_type && !payload.is_revenue_slab">
      <b-row class="payout-slab" v-for="(rule, index) in rules" :key="index">
        <b-col>
          <strong>CUSTOMER TYPE</strong>
          <br />
          <span>{{ rule.customer_type }}</span>
        </b-col>
        <b-col>
          <strong>PAYOUT</strong>
          <br />
          <b-form>
            <b-input-group>
              <template #prepend>
                <b-dropdown
                  disabled
                  :text="
                    rule.payout_type === 'CPS'
                      ? rule.payout_percent
                      : rule.payout_currency
                  "
                  class="currency-dropdown"
                >
                  <b-dropdown-item
                    v-if="rule.payout_type == 'CPO'"
                    @click="rule.payout_currency = 'AED'"
                    >AED</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="rule.payout_type == 'CPO'"
                    @click="rule.payout_currency = 'USD'"
                    >USD</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="rule.payout_type == 'CPS'"
                    @click="rule.payout_percent = '%'"
                    >%</b-dropdown-item
                  >
                </b-dropdown>
              </template>

              <b-form-input type="number" v-model="rule.payout"></b-form-input>

              <template #append>
                <b-dropdown :text="rule.payout_type" class="payout-dropdown">
                  <b-dropdown-item
                    @click="
                      rule.payout_type = 'CPO';
                      changeInPayoutType(rule);
                    "
                    >CPO</b-dropdown-item
                  >
                  <b-dropdown-item
                    @click="
                      rule.payout_type = 'CPS';
                      changeInPayoutType(rule);
                    "
                    >CPS</b-dropdown-item
                  >
                  <!-- <b-dropdown-item @click="rule.payout_type = 'CPC';changeInPayoutType(rule)"
                    >CPC</b-dropdown-item
                  > -->
                </b-dropdown>
              </template>
            </b-input-group>
          </b-form>
        </b-col>
      </b-row>
    </div>

    <!-- If the user adds only revenue slabs and not customer type-->
    <div v-if="payload.is_revenue_slab && !payload.is_customer_type">
      <b-row class="payout-slab" v-for="(rule, index) in rules" :key="index">
        <b-col>
          <strong>{{ payload.is_order ? "ORDER" : "REVENUE" }} SLAB</strong>
          <br />
          <span v-if="!payload.is_order"
            >from {{ payload.default_currency }} {{ rule.from_revenue }} to
            {{ payload.default_currency }} {{ rule.to_revenue }}</span
          >
          <span v-if="payload.is_order"
            >from {{ rule.from_order }} to {{ rule.to_order }}</span
          >
        </b-col>
        <b-col>
          <strong>PAYOUT</strong>
          <br />
          <b-form>
            <b-input-group>
              <template #prepend>
                <b-dropdown
                  disabled
                  :text="
                    rule.payout_type === 'CPS'
                      ? rule.payout_percent
                      : rule.payout_currency
                  "
                  class="currency-dropdown"
                >
                  <b-dropdown-item
                    v-if="rule.payout_type == 'CPO'"
                    @click="rule.payout_currency = 'AED'"
                    >AED</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="rule.payout_type == 'CPO'"
                    @click="rule.payout_currency = 'USD'"
                    >USD</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="rule.payout_type == 'CPS'"
                    @click="rule.payout_percent = '%'"
                    >%</b-dropdown-item
                  >
                </b-dropdown>
              </template>

              <b-form-input type="number" v-model="rule.payout"></b-form-input>

              <template #append>
                <b-dropdown :text="rule.payout_type" class="payout-dropdown">
                  <b-dropdown-item
                    @click="
                      rule.payout_type = 'CPO';
                      changeInPayoutType(rule);
                    "
                    >CPO</b-dropdown-item
                  >
                  <b-dropdown-item
                    @click="
                      rule.payout_type = 'CPS';
                      changeInPayoutType(rule);
                    "
                    >CPS</b-dropdown-item
                  >
                  <!-- <b-dropdown-item @click="rule.payout_type = 'CPC';changeInPayoutType(rule)"
                    >CPC</b-dropdown-item
                  > -->
                </b-dropdown>
              </template>
            </b-input-group>
          </b-form>
        </b-col>
      </b-row>
    </div>

    <!-- if user select both revenue_slab & customer type-->
    <div v-if="payload.is_revenue_slab && payload.is_customer_type">
      <b-row class="payout-slab" v-for="(rule, index) in rules" :key="index">
        <b-col>
          <strong>{{ payload.is_order ? "ORDER" : "REVENUE" }} SLAB</strong>
          <br />
          <span v-if="!payload.is_order"
            >from {{ payload.default_currency }} {{ rule.from_revenue }} to
            {{ payload.default_currency }} {{ rule.to_revenue }}</span
          >
          <span v-if="payload.is_order"
            >from {{ rule.from_order }} to {{ rule.to_order }}</span
          >
        </b-col>
        <b-col>
          <strong>CUSTOMER TYPE</strong>
          <br />
          <span>{{ rule.customer_type }}</span>
        </b-col>
        <b-col>
          <strong>PAYOUT</strong>
          <br />
          <b-form>
            <b-input-group>
              <template #prepend>
                <b-dropdown
                  :text="
                    rule.payout_type === 'CPS'
                      ? rule.payout_percent
                      : rule.payout_currency
                  "
                  class="currency-dropdown"
                >
                  <b-dropdown-item
                    v-if="rule.payout_type == 'CPO'"
                    @click="rule.payout_currency = 'AED'"
                    >AED</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="rule.payout_type == 'CPO'"
                    @click="rule.payout_currency = 'USD'"
                    >USD</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="rule.payout_type == 'CPS'"
                    @click="rule.payout_percent = '%'"
                    >%</b-dropdown-item
                  >
                </b-dropdown>
              </template>

              <b-form-input type="number" v-model="rule.payout"></b-form-input>

              <template #append>
                <b-dropdown :text="rule.payout_type" class="payout-dropdown">
                  <b-dropdown-item
                    @click="
                      rule.payout_type = 'CPO';
                      changeInPayoutType(rule);
                    "
                    >CPO</b-dropdown-item
                  >
                  <b-dropdown-item
                    @click="
                      rule.payout_type = 'CPS';
                      changeInPayoutType(rule);
                    "
                    >CPS</b-dropdown-item
                  >
                  <!-- <b-dropdown-item @click="rule.payout_type = 'CPC';changeInPayoutType(rule)"
                    >CPC</b-dropdown-item
                  > -->
                </b-dropdown>
              </template>
            </b-input-group>
          </b-form>
        </b-col>
      </b-row>
    </div>

    <!-- ADD BUTTON -->
    <b-row class="mt-15 float-right">
      <b-col>
        <b-button :disabled="isLoading" class="cancel-btn" @click="handleCancel"
          >Cancel
        </b-button>
      </b-col>
      <b-col>
        <b-button :disabled="isLoading" class="add-btn" @click="handleSubmit"
          >{{ type == "edit" ? "Update" : "Submit" }}
          <b-spinner v-if="isLoading" small type="grow"></b-spinner>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<style lang="scss">
.create-payout-step2 {
  .payout-slab {
    box-shadow: 0px 12px 44px #7272721a;
    border-radius: 20px;
    padding: 30px;
    margin: 30px;
  }
  .add-btn {
    width: 229px;
    height: 53px;
    background: #1374d6 0% 0% no-repeat padding-box;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.6px;
    color: #ffffff;
  }
  .cancel-btn {
    width: 229px;
    height: 53px;
    background: #bdd8f4;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.6px;
    color: #1374d6;
  }
}
</style>

<script>
import ApiService from "../../../core/services/api.service";
import ToasterService from "../../../core/services/toaster.service";
import AlertDialog from "@/core/components/alert.component.vue";

export default {
  name: "create-payout-step2",
  props: ["payload", "type"],
  components: {
    AlertDialog,
  },
  data: () => ({
    errors: "",
    client_id: null,
    rules: [],
    isLoading: false,
  }),
  mounted() {
    if (this.type == "edit") {
      this.client_id = this.payload.client_id;
    } else {
      this.client_id = this.$route.params.id;
    }
    this.mappedRules();
  },
  methods: {
    handleSubmit() {
      // delete this.payload.is_customer_type;
      // delete this.payload.is_order;
      //  delete this.payload.is_revenue_slab;
      console.log("this.rules", this.rules);
      let payloadBody = {
        ...this.payload,
        payout_rules: this.rules,
        influencer_id: "5fe96cea75d92e0f8a445a42",
        coupon_series: this.payload.coupon_series.map((x) => x._id),
        payout_name: this.payload.name,
        is_revenue_slab: this.payload.is_revenue_slab,
        payout_rule_type: this.payload.is_revenue_slab
          ? this.payload.is_order
            ? "order"
            : "revenue"
          : "",
      };
      this.isLoading = true;
      console.log("final payload", payloadBody);
      if (this.type == "edit") {
        payloadBody.payout_id = this.$route.params.id;

        ApiService.post("/payout/edit-payout", payloadBody)
          .then((response) => {
            localStorage.removeItem("copyPayout");
            console.log(response);
            this.isLoading = false;
            if (!response.data.status) {
              this.errors = response.data.message[0];
              this.$refs.alertDialog.dialog = true;
              return;
            }
            ToasterService.makeToast(
              this,
              "",
              response.data.message[0],
              "info"
            );
            this.$router
              .push({
                name: "payout",
                params: { id: this.client_id },
              })
              .then(() => {
                ToasterService.makeToast(
                  this,
                  `${!response.data.status ? "Error" : "Success"}`,
                  response.data.message[0],
                  `${!response.data.status ? "danger" : "info"}`
                );
              });
          })
          .catch((err) => {
            console.log(err);
            ToasterService.makeToast(this, "", err.message, "danger");
          });
      } else {
        ApiService.post("/payout/create", payloadBody)
          .then((response) => {
            console.log("*****", response, response.data.message[0]);
            this.isLoading = false;
            if (!response.data.status) {
              this.errors = response.data.message[0];
              this.$refs.alertDialog.dialog = true;
              return;
            }
            this.$router
              .push({
                name: "payout",
                params: { id: this.client_id },
              })
              .then(() => {
                ToasterService.makeToast(
                  this,
                  `${!response.data.status ? "Error" : "Success"}`,
                  response.data.message[0],
                  `${!response.data.status ? "danger" : "info"}`
                );
              });
          })
          .catch((err) => {
            console.log(err);
            ToasterService.makeToast(this, "", err.message, "danger");
          });
      }
    },
    handleCancel() {
      this.$router.push({ name: "payout", params: { id: this.client_id } });
    },

    formatDate(dateString) {
      if (!dateString) {
        return;
      }
      let options = { day: "numeric", month: "long", year: "numeric" };
      let date = new Date(dateString);
      return date.toLocaleDateString("en-US", options);
    },

    mappedRules() {
      // if (this.type == "edit") {
      //   this.rules = this.payload.payout_rules;
      //   return;
      // }
      // if user not select revenue_slab & customer type both
      if (!this.payload.is_revenue_slab && !this.payload.is_customer_type) {
        this.rules = [];
        this.rules = [
          {
            payout_type: this.payload.default_payout_type,
            payout: this.payload.payout_rules[0]
              ? this.payload.payout_rules[0].payout
              : null,
            payout_percent:
              this.payload.default_payout_type == "CPS" ? "%" : "",
            payout_currency:
              this.payload.default_payout_type == "CPO"
                ? this.payload.default_currency
                : "",
          },
        ];
      }

      // <!-- If the user adds only customer type and not revenue slabs-->
      if (this.payload.is_customer_type && !this.payload.is_revenue_slab) {
        this.rules = [];
        for (let i = 0; i < this.payload.customer_type.length; i++) {
          this.rules.push({
            payout_type: this.payload.default_payout_type,
            payout: this.payload.payout_rules[i]
              ? this.payload.payout_rules[i].payout
              : null,
            payout_percent:
              this.payload.default_payout_type == "CPS" ? "%" : "",
            payout_currency:
              this.payload.default_payout_type == "CPO"
                ? this.payload.default_currency
                : "",
            customer_type: this.payload.customer_type[i],
          });
        }
      }

      // <!-- If the user adds only revenue slabs and not customer type-->
      if (this.payload.is_revenue_slab && !this.payload.is_customer_type) {
        this.rules = [];
        for (let i = 0; i < this.payload.payout_rules.length; i++) {
          this.rules.push({
            payout_type: this.payload.default_payout_type,
            payout: this.payload.payout_rules[i].payout,
            payout_percent:
              this.payload.default_payout_type == "CPS" ? "%" : "",
            payout_currency:
              this.payload.default_payout_type == "CPO"
                ? this.payload.default_currency
                : "",
            from_revenue: this.payload.payout_rules[i].from_revenue,
            to_revenue: this.payload.payout_rules[i].to_revenue,
            from_order: this.payload.payout_rules[i].from_order,
            to_order: this.payload.payout_rules[i].to_order,
          });
        }
      }

      // <!-- if user select both revenue_slab & customer type-->
      if (this.payload.is_revenue_slab && this.payload.is_customer_type) {
        this.rules = [];
        for (let i = 0; i < this.payload.payout_rules.length; i++) {
          for (let j = 0; j < this.payload.customer_type.length; j++) {
            this.rules.push({
              payout_type: this.payload.default_payout_type,
              payout: this.payload.payout_rules[i].payout,
              payout_percent:
                this.payload.default_payout_type == "CPS" ? "%" : "",
              payout_currency:
                this.payload.default_payout_type == "CPO"
                  ? this.payload.default_currency
                  : "",
              customer_type: this.payload.customer_type[j],
              from_revenue: this.payload.payout_rules[i].from_revenue,
              to_revenue: this.payload.payout_rules[i].to_revenue,
              from_order: this.payload.payout_rules[i].from_order,
              to_order: this.payload.payout_rules[i].to_order,
            });
          }
        }
      }
    },
    changeInPayoutType(rule) {
      if (rule.payout_type == "CPS") {
        rule.payout_percent = "%";
        rule.payout_currency = "";
      } else {
        rule.payout_currency = this.payload.default_currency;
        rule.payout_percent = "";
      }
    },
  },
  computed: {},
};
</script>
